import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { media } from '../utils/media-queries';
import Layout from '../components/layout';
import SEO from '../components/seo';
import RichText from '../components/richtext';

const PricePage = ({ data }) => {
  const { backgroundColor, title, _rawDescription } = data.price.nodes[0];

  return (
    <Layout color={backgroundColor.hex}>
      <SEO siteTitle="preise" />
      <StyledPrice>
        <h2 className="headline">{title}</h2>
        <div className="text">
          <RichText blocks={_rawDescription} />
        </div>
      </StyledPrice>
    </Layout>
  );
};

const StyledPrice = styled.section`
  height: 95vh;
  overflow-x: hidden;
  padding: var(--spacing-XS) var(--spacing-XXS);

  .headline {
    padding-bottom: var(--spacing-XXS);
  }

  @media ${media.M} {
    height: 80vh;
    padding: 0;

    .headline {
      padding-bottom: var(--spacing-S);
    }

    .text {
      padding-right: var(--spacing-XL);
    }
  }
`;

export const query = graphql`
  query Preise {
    price: allSanityPrice {
      nodes {
        title
        _rawDescription
        backgroundColor {
          hex
        }
      }
    }
  }
`;

export default PricePage;
